<template>
  <div id="portfolio-creator-calculator">
    <div v-if="_g_UserFeatures.permissions.index_creator" class="portfolio-creator-view">
      <div class="portfolio-content-grid">
        <!-- Left column: Chart and metrics -->
        <div class="chart-column">
          <div class="metrics-grid">
            <div class="metric-card">
              <h3 class="metric-title">Rentabilidad</h3>
              <div class="metric-value" :class="profit >= 0 ? 'positive-value' : 'negative-value'">
                {{profit ? (profit > 0 ? '+' : '') + profit.toFixed(2) + '%' : '0.00%'}}
              </div>
            </div>
            <div class="metric-card">
              <h3 class="metric-title">Máximo Drawdown</h3>
              <div class="metric-value">
                {{max_drawdown ? max_drawdown.toFixed(2) + '%' : '0.00%'}}
              </div>
            </div>
          </div>

          <div class="chart-container" :style="{ height: chartContainerHeight }">
            <!-- Add loading indicator -->
            <div v-if="isChartLoading" class="chart-loading-overlay">
              <div class="loading-spinner"></div>
              <span>Cargando datos...</span>
            </div>
            <div id="highchart-content" :style="{ height: '100%' }"></div>
          </div>

          <div class="info-panel">
            <p class="info-text-style">
              Añada tickers a su índice personalizado para simular su comportamiento. Puede guardar múltiples índices y cargarlos posteriormente.
            </p>
          </div>
        </div>

        <!-- Right column: Tickers and controls -->
        <div class="tickers-column">
          <!-- Active index status display -->
          <div class="selected-index-container" v-if="selected_index && selectedIndexFromModal && !showLoadIndexModal">
            <div class="selected-index-header">
              <h3 class="selected-index-title">Viendo índice: <span>{{ selectedIndexFromModal.name }}</span></h3>
              <p v-if="hasIndexChanges" class="changes-status">
                <i class="material-icons">edit</i>
                <span>Se han realizado cambios</span>
              </p>
            </div>
            <div class="selected-index-actions">
              <button v-if="hasIndexChanges"
                     class="action-btn success-btn" 
                     @click="_saveIndexChanges" 
                     :disabled="user_tickers.length === 0">
                <i class="material-icons">save</i>
                <span>Guardar cambios</span>
              </button>
              <button v-if="hasIndexChanges"
                     class="action-btn warning-btn" 
                     @click="_discardIndexChanges">
                <i class="material-icons">replay</i>
                <span>Descartar</span>
              </button>
              <button class="action-btn tertiary-btn" @click="_deselectIndex">
                <i class="material-icons">close</i>
                <span>Deseleccionar</span>
              </button>
            </div>
          </div>

          <div class="tickers-section">
            <div class="section-header">
              <h2 class="section-title">Tickers Seleccionados</h2>
              <div class="section-actions">
                <button class="action-btn primary-btn add-ticker-btn" @click="_openTickerSearchModal">
                  <i class="material-icons">add</i>
                  <span>Añadir ticker</span>
                </button>
              </div>
            </div>

            <!-- Empty state -->
            <div v-if="user_tickers.length === 0" class="empty-tickers-state">
              <i class="material-icons empty-icon">playlist_add</i>
              <p>No hay tickers seleccionados</p>
              <p>Añada tickers para crear su índice personalizado</p>
            </div>

            <!-- Tickers list -->
            <div v-else class="tickers-list">
              <div class="ticker-item" v-for="(ticker, index) in user_tickers" :key="ticker">
                <div class="ticker-info">
                  <span class="ticker-label">{{ticker}}</span>
                </div>
                <button @click="_deleteTicker(index)" class="icon-button delete-button">
                  <i class="material-icons">delete</i>
                </button>
              </div>
            </div>
          </div>

          <!-- Portfolio actions -->
          <div class="portfolio-actions">
            <button class="action-btn primary-btn action-btn-lg" @click="_openLoadIndexModal">
              <i class="material-icons">folder_open</i>
              Cargar índice
            </button>
            <button class="action-btn primary-btn action-btn-lg" @click="_openSaveIndexModal">
              <i class="material-icons">save</i>
              Guardar índice
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="not-available-data-container">
      <div class="info-ticker-box">
        <p class="text-info-ticker">Ticker: <span class="text-bold">{{_g_SelectedTicker.ticker}}</span></p>
      </div>
      <p class="not-available-data typo-body-2"> Su plan actual no tiene acceso a esta herramienta. </p>
      <a href="/app/store" class="link-to-store typo-body-2"> Pulse aquí para cambiar de plan. </a>
    </div>

    <!-- Modal para cargar índices -->
    <div class="modal-overlay" v-if="showLoadIndexModal" @click="_closeLoadIndexModal">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3 class="modal-title">Cargar índice</h3>
          <button class="modal-close-btn" @click="_closeLoadIndexModal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-indices-list">
            <div 
              v-for="index in user_indices_creator" 
              :key="index.icid" 
              class="modal-index-item"
              @click="_selectIndexFromModal(index)"
              :class="{ 'selected-index': selected_index == index.icid }"
            >
              <div class="modal-index-info">
                <span class="modal-index-name">{{index.name}}</span>
                <span class="modal-index-tickers">{{index.tickers}}</span>
              </div>
              <div class="modal-index-actions">
                <button @click.stop="_editIndex(index)" class="icon-button edit-index-btn">
                  <i class="material-icons">edit</i>
                </button>
                <button @click.stop="_removeIndex(index)" class="icon-button delete-index-btn">
                  <i class="material-icons">delete</i>
                </button>
              </div>
            </div>
            <div v-if="user_indices_creator.length === 0" class="no-indices-message">
              No hay índices guardados
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="action-btn secondary-btn" @click="_closeLoadIndexModal">Cancelar</button>
          <button 
            class="action-btn primary-btn" 
            @click="_confirmLoadIndex" 
            :disabled="!selected_index"
          >
            Cargar
          </button>
        </div>
      </div>
    </div>

    <!-- Modal de confirmación para eliminar índice -->
    <div class="modal-overlay" v-if="showDeleteConfirmModal" @click="_closeDeleteConfirmModal">
      <div class="modal-container delete-confirm-modal" @click.stop>
        <div class="modal-header">
          <h3 class="modal-title">Confirmar eliminación</h3>
          <button class="modal-close-btn" @click="_closeDeleteConfirmModal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body">
          <p class="delete-confirm-message">
            ¿Está seguro que desea eliminar el índice "<strong>{{ indexToDelete ? indexToDelete.name : '' }}</strong>"?
          </p>
          <p class="delete-warning">
            <i class="material-icons warning-icon">warning</i>
            Esta acción no se puede deshacer.
          </p>
        </div>
        <div class="modal-footer">
          <button class="action-btn secondary-btn" @click="_closeDeleteConfirmModal">Cancelar</button>
          <button class="action-btn delete-btn" @click="_confirmDeleteIndex">
            Eliminar
          </button>
        </div>
      </div>
    </div>

    <!-- Modal para guardar índice -->
    <div class="modal-overlay" v-if="showSaveIndexModal" @click="_closeSaveIndexModal">
      <div class="modal-container save-index-modal" @click.stop>
        <div class="modal-header">
          <h3 class="modal-title">Guardar nuevo índice</h3>
          <button class="modal-close-btn" @click="_closeSaveIndexModal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body">
          <div class="save-index-form">
            <div class="form-group">
              <label for="index-name-input" class="input-label">Nombre del índice</label>
              <input 
                type="text" 
                id="index-name-input" 
                class="vanilla-input" 
                v-model="index_creator_name" 
                placeholder="Ingrese nombre del índice"
                :maxlength="30"
              />
              <div class="input-helper" v-if="index_creator_name">
                <span>{{index_creator_name.length}} / 30</span>
              </div>
            </div>
            <div class="index-tickers-summary" v-if="user_tickers.length > 0">
              <p class="tickers-label">Tickers seleccionados:</p>
              <p class="tickers-list">{{user_tickers.join(', ')}}</p>
            </div>
            <div class="no-tickers-message" v-else>
              <p>No hay tickers seleccionados.</p>
              <p class="tickers-helper">Añada tickers para incluirlos en su índice personalizado.</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="action-btn secondary-btn" @click="_closeSaveIndexModal">Cancelar</button>
          <button 
            class="action-btn primary-btn" 
            @click="_saveIndex" 
            :disabled="!index_creator_name || user_tickers.length === 0"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>

    <!-- Modal para buscar ticker -->
    <div class="modal-overlay" v-if="showTickerSearchModal" @click="_closeTickerSearchModal">
      <div class="modal-container ticker-search-modal" @click.stop>
        <div class="modal-header">
          <h3 class="modal-title">Buscar ticker</h3>
          <button class="modal-close-btn" @click="_closeTickerSearchModal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body">
          <div class="ticker-search-form">
            <div class="form-group">
              <label for="ticker-search-input" class="input-label">Buscar ticker para añadir</label>
              <div class="autocomplete-wrapper">
                <AutocompleteInput
                  :options="options"
                  :show="showTickerSearchModal"
                  :disabled="disabled_ticker_to_check"
                  @select="_selectSuggestions"
                  @suggestions="_autocompleteSuggestions"
                  placeholder="Introduzca el ticker a buscar">
                  <template slot="item" scope="option">
                    <article class="autocomplete-item">
                      <div class="autocomplete-item-content">
                        <p class="autocomplete-item-title">{{ option.title }}</p>
                        <p class="autocomplete-item-description">{{ option.description }}</p>
                      </div>
                    </article>
                  </template>
                </AutocompleteInput>
              </div>
              <p class="search-helper-text">Introduzca un término para buscar tickers disponibles</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="action-btn secondary-btn" @click="_closeTickerSearchModal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Vue from 'vue';
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";
import Highcharts from "highcharts";
import AutocompleteInput from "@/components/helpers/AutocompleteInput";
import Button from "@/components/helpers/Button.vue";
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';
import inputIndexName from '@/components/stockchart/components/configs/input_simulator.json';

export default {
  mixins:[APICaller],
  components: {
    AutocompleteInput,
    Button,
    InputTextOutline
  },
  name: 'PortfolioCreatorCalculator',
  data: () => ({
    selected_index: "",
    input_IndexName : inputIndexName,
    show: true,
    disabled_ticker_to_check: false,
    options: [],
    user_tickers: [],
    original_tickers: [],
    index_creator_name: '',
    user_indices_creator: [],
    series: [{
      name: 'Indice de cartera',
      data: [0, 0]
    }],
    categories: [],
    user_portfolios: [],

    table_header: ['Rentabilidad', 'Max.Drawdown'],
    profit: 0,
    max_drawdown: 0,

    num: 2,
    button_name_save: "Guardar índice",
    button_icon_save: "none",
    button_key_save: "button_key_save",

    // id_header and title_header removed
    input_IndexName_value: '',
    showLoadIndexModal: false,
    selectedIndexFromModal: null,
    showDeleteConfirmModal: false,
    indexToDelete: null,
    _shouldReopenLoadModal: false,
    showSaveIndexModal: false,
    showTickerSearchModal: false,
    // Add chart loading state and debounce control
    isChartLoading: false,
    chartDebounceTimer: null,
    chartDebounceDelay: 300,
    // Enhance with additional performance controls
    chartUpdateInProgress: false,
    lastUpdateTimestamp: 0,
    isViewportMobile: false,
    // Add a variable to track chart container height
    chartContainerHeight: '60vh',
  }),
  computed: {
    ...mapGetters(['_g_SelectedTicker']),
    ...mapGetters(['_g_UserFeatures']),
    
    // Check if the current ticker is already added to the list
    isCurrentTickerAdded() {
      // Add a check for _g_SelectedTicker existence
      return this._g_SelectedTicker && this._g_SelectedTicker.ticker && 
             this.user_tickers.includes(this._g_SelectedTicker.ticker);
    },
    
    // Check if there are changes to the currently selected index
    hasIndexChanges() {
      if (!this.selectedIndexFromModal) return false;
      // Ensure arrays exist before accessing length
      if (!this.original_tickers || !this.user_tickers) return false; 
      // Handle case where original tickers might be initially empty after loading but before changes
      // if (this.original_tickers.length === 0) return false; // This line might prevent showing changes if initially empty
      
      // Sort copies of arrays for comparison to handle order differences
      const sortedUserTickers = [...this.user_tickers].sort();
      const sortedOriginalTickers = [...this.original_tickers].sort();

      if (sortedUserTickers.length !== sortedOriginalTickers.length) return true;
      
      // Compare sorted arrays element by element
      for (let i = 0; i < sortedUserTickers.length; i++) {
        if (sortedUserTickers[i] !== sortedOriginalTickers[i]) {
          return true;
        }
      }
      
      return false;
    }
  },
  created() {
    // Check for mobile viewport on creation
    this._checkViewportSize();
    
    // Initialize throttled window resize handler
    this._throttledResizeHandler = this._throttle(() => {
      this._checkViewportSize();
    }, 250);
    
    // If _g_SelectedTicker is needed on load but might not be set yet, consider initializing it or fetching it here.
  },
  mounted() {
    // Add event listeners
    window.addEventListener('resize', this._throttledResizeHandler);
    
    // Initialize chart with empty data first
    this._addDataToHighchart();
    this._loadAllIndexCreator();
    // Start loading data
    this._getData();
    
    // Improve resize handling with a more robust approach
    this.$nextTick(() => {
      window.addEventListener('resize', this._debouncedReflow);
      this._initResizeObserver();
      // Trigger initial reflow after a short delay
      setTimeout(() => {
        this._reflowChart();
      }, 200);
    });
  },
  beforeDestroy() {
    // Clean up all resources
    window.removeEventListener('resize', this._debouncedReflow);
    window.removeEventListener('resize', this._throttledResizeHandler);
    if (this.$_resizeObserver) {
      this.$_resizeObserver.disconnect();
    }
    // Clear any pending debounce timers
    if (this.chartDebounceTimer) {
      clearTimeout(this.chartDebounceTimer);
    }
    // Destroy the chart instance if it exists
    if (this.$_chart) {
        try {
            this.$_chart.destroy();
            this.$_chart = null; // Clear reference
        } catch (e) {
            console.error("Error destroying Highcharts chart:", e);
        }
    }
  },
  methods: {
    // Viewport size detection
    _checkViewportSize() {
      const oldMobileState = this.isViewportMobile;
      this.isViewportMobile = window.innerWidth < 768;
      
      // If mobile state changed, update chart container height
      if (oldMobileState !== this.isViewportMobile) {
        // Give the DOM time to update before recalculating
        this.$nextTick(() => {
          this._updateChartContainerHeight();
        });
      }
    },
    
    // Throttling utility for handling rapid events like resize
    _throttle(callback, limit) {
      let waiting = false;
      return function() {
        if (!waiting) {
          callback.apply(this, arguments);
          waiting = true;
          setTimeout(() => {
            waiting = false;
          }, limit);
        }
      };
    },
    
    // Added debounced reflow method for better performance
    _debouncedReflow() {
      if (this.chartDebounceTimer) {
        clearTimeout(this.chartDebounceTimer);
      }
      this.chartDebounceTimer = setTimeout(() => {
        this._reflowChart();
      }, this.chartDebounceDelay);
    },
    
    // Dedicated method for chart reflow operations
    _reflowChart() {
      if (this.$_chart && typeof this.$_chart.reflow === 'function') {
        // Update container height before reflowing
        const chartContent = document.getElementById('highchart-content');
        if (chartContent) {
          chartContent.style.height = this.chartContainerHeight;
        }
        
        this.$_chart.reflow();
        
        // Force update of chart dimensions
        if (this.$_chart.setSize) {
          this.$_chart.setSize();
        }
      }
    },
    
    // Improved resize observer initialization
    _initResizeObserver() {
      if (window.ResizeObserver) {
        // Calculate available height for chart container
        this._updateChartContainerHeight();
        
        const chartContent = document.getElementById('highchart-content');
        if (chartContent) {
          this.$_resizeObserver = new ResizeObserver(entries => {
            // Update chart container height when resized
            this._updateChartContainerHeight();
            
            // Use debounced reflow for better performance
            this._debouncedReflow();
          });
          this.$_resizeObserver.observe(chartContent);
          
          // Also observe parent container for more reliable size change detection
          const chartContainer = document.querySelector('.chart-container');
          if (chartContainer) {
            this.$_resizeObserver.observe(chartContainer);
          }
          
          // Observe the entire portfolio view to handle layout changes
          const portfolioView = document.querySelector('.portfolio-creator-view');
          if (portfolioView) {
            this.$_resizeObserver.observe(portfolioView);
          }
        }
      }
    },
    
    // Calculate the appropriate height for chart container
    _updateChartContainerHeight() {
      // Get viewport height
      const viewportHeight = window.innerHeight;
      
      // Get portfolio view container for available space calculation
      const portfolioView = document.querySelector('.portfolio-creator-view');
      const viewPadding = this.isViewportMobile ? 20 : 40; // Account for top and bottom padding
      
      // Get other elements' heights
      const metricsGrid = document.querySelector('.metrics-grid');
      const infoPanel = document.querySelector('.info-panel');
      
      let metricsHeight = metricsGrid ? metricsGrid.offsetHeight : 0;
      let infoPanelHeight = infoPanel ? infoPanel.offsetHeight : 0;
      
      // Add gaps between components (20px gap × 2)
      const verticalGap = 20;
      const gaps = verticalGap * 2;
      
      // On mobile layout, we use a different approach since the layout flows differently
      if (this.isViewportMobile || window.innerWidth < 992) {
        // On mobile, we use a fixed reasonable height based on viewport
        const mobileHeight = Math.max(250, Math.min(viewportHeight * 0.5, 500));
        this.chartContainerHeight = `${mobileHeight}px`;
      } else {
        // On desktop, calculate available space in the container
        let availableHeight = viewportHeight - viewPadding - metricsHeight - infoPanelHeight - gaps;
        
        // Ensure a reasonable minimum height
        const minHeight = 300;
        availableHeight = Math.max(minHeight, availableHeight);
        
        // Set height in pixels instead of vh for more precise control
        this.chartContainerHeight = `${availableHeight}px`;
      }
      
      // Apply height directly to chart content element for immediate effect
      const chartContent = document.getElementById('highchart-content');
      if (chartContent) {
        chartContent.style.height = '100%'; // Always fill container height
      }
      
      // Update the chart container
      const chartContainer = document.querySelector('.chart-container');
      if (chartContainer) {
        chartContainer.style.height = this.chartContainerHeight;
      }
      
      // Reflow chart after height change
      this._debouncedReflow();
    },
    
    //Method that selects the color of the profit text according to whether the profitability is positive or negative.
    _colorProfitIndex: function(profit){
      // Ensure profit is a number before comparing
      const numericProfit = Number(profit);
      if(numericProfit === 0){
        // Use a neutral color for zero, or keep error color if intended
        return 'var(--color-text-secondary, #606577)'; // Example neutral color
        // return "#B00020"; // Original color
      }
      else if(numericProfit < 0){
        return 'var(--color-error, #B00020)'; // Use CSS variable
      } else{
        return 'var(--color-success, #57a869)'; // Use CSS variable
      }
    },

    //Method that configures the data with which the graphics will be printed
    _addDataToHighchart: function(){
      let self = this;
      // Set loading state
      this.isChartLoading = true;
      this.chartUpdateInProgress = true;
      
      // Track update time to avoid too frequent redraws
      const now = Date.now();
      const timeSinceLastUpdate = now - this.lastUpdateTimestamp;
      
      // If updates are happening too quickly, delay this update
      if (timeSinceLastUpdate < 300) {
        setTimeout(() => {
          this._addDataToHighchart();
        }, 300 - timeSinceLastUpdate);
        return;
      }
      
      this.lastUpdateTimestamp = now;
      
      // Use requestAnimationFrame for better rendering performance
      requestAnimationFrame(() => {
         const chartContent = document.getElementById('highchart-content');
         // Check if the target element exists
         if (!chartContent) {
             console.error("Highcharts target element 'highchart-content' not found.");
             this.isChartLoading = false;
             this.chartUpdateInProgress = false;
             return; 
         }
         
         // Destroy existing chart before creating a new one
         if (this.$_chart) {
            try {
                this.$_chart.destroy();
            } catch(e) {
                console.error("Error destroying previous chart:", e);
            }
            this.$_chart = null; 
         }

        // Store chart reference for later use
        try {
            // Adjust chart options based on viewport
            const chartOptions = {
              chart: {
                  type: 'line',
                  zoomType: 'x',
                  backgroundColor: 'transparent',
                  reflow: true,
                  spacingTop: this.isViewportMobile ? 20 : 30,
                  spacingBottom: this.isViewportMobile ? 15 : 20,
                  spacingLeft: this.isViewportMobile ? 15 : 20,
                  spacingRight: this.isViewportMobile ? 15 : 25,
                  marginRight: this.isViewportMobile ? 15 : 25,
                  marginLeft: this.isViewportMobile ? 5 : 5,
                  animation: {
                    duration: 150 // Faster animations for better responsiveness
                  },
                  events: {
                    load: function() {
                      // Clear loading state when chart is fully loaded
                      self.isChartLoading = false;
                      self.chartUpdateInProgress = false;
                      
                      // Don't reflow immediately for better performance
                      setTimeout(() => {
                        this.reflow();
                        
                        // Adjust extremes to ensure all data is visible
                        this.redraw(false); // Force a redraw without animation first for performance
                        
                        const xAxis = this.xAxis[0];
                        if (xAxis && self.series && self.series[0] && self.series[0].data && self.series[0].data.length > 0) {
                          const minX = 0;
                          const maxX = self.series[0].data.length > 1 ? self.series[0].data.length - 1 : 1; 
                          xAxis.setExtremes(minX, maxX, true, false);
                        }
                      }, 50);
                    },
                    redraw: function() {
                      // Adjust extremes on redraw as well
                      const xAxis = this.xAxis[0];
                      if (xAxis && self.series && self.series[0] && self.series[0].data && self.series[0].data.length > 0) {
                        const minX = 0;
                        const maxX = self.series[0].data.length > 1 ? self.series[0].data.length - 1 : 1;
                        // Check current extremes before setting to avoid unnecessary redraw loops
                        if (xAxis.min !== minX || xAxis.max !== maxX) {
                           xAxis.setExtremes(minX, maxX, true, false);
                        }
                      }
                    }
                  }
              },
              title: {
                  text: '',
              },
              xAxis: {
                  visible: false,
                  gridLineWidth: 0,
                  lineWidth: 0,
                  endOnTick: false,
                  startOnTick: false,
                  min: null,
                  max: null,
                  minPadding: 0.05,
                  maxPadding: 0.05,
              },
              yAxis: {
                  title: '',
                  gridLineColor: 'rgba(0, 0, 0, 0.08)',
                  gridLineWidth: this.isViewportMobile ? 0 : 1, // Remove grid on mobile for better performance
                  lineWidth: 0,
                  tickWidth: 0,
                  endOnTick: false,
                  minPadding: 0.1,
                  maxPadding: 0.25,
                  labels: {
                    style: {
                      color: 'var(--color-text-secondary, #606577)'
                    }
                  }
              },
              tooltip: {
                  shared: true,
                  crosshairs: {
                    width: 1,
                    color: 'var(--color-outline, rgba(0, 0, 0, 0.2))',
                    dashStyle: 'shortdot'
                  },
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  borderColor: 'var(--color-outline, rgba(0, 0, 0, 0.12))',
                  borderWidth: 1,
                  borderRadius: 5,
                  shadow: false,
                  // Improve tooltip performance
                  animation: false,
                  hideDelay: 0,
                  followPointer: true, // Better for touch devices
                  followTouchMove: this.isViewportMobile
              },
              plotOptions: {
                  line: {
                    lineWidth: this.isViewportMobile ? 2 : 3, // Thinner lines on mobile
                    states: {
                      hover: {
                        lineWidth: this.isViewportMobile ? 3 : 4
                      }
                    },
                    marker: {
                      enabled: false,
                      radius: this.isViewportMobile ? 2 : 3,
                      states: {
                        hover: {
                          enabled: true,
                          radius: this.isViewportMobile ? 4 : 5
                        }
                      }
                    }
                  },
                  series: {
                    animation: {
                      duration: this.isViewportMobile ? 100 : 150 // Even faster on mobile
                    },
                    clip: false,
                    turboThreshold: 5000 // Increase point threshold for larger datasets
                  }
              },
              series: self.series && Array.isArray(self.series) && self.series.length 
                  ? self.series 
                  : [{ 
                      name: 'Índice de cartera',
                      data: [0, 0],
                      color: 'var(--primary, #00aedd)'
                  }],
              colors: ['var(--primary, #00aedd)', 'var(--color-text-primary, #0F0F1A)'],
              credits: {
                  enabled: false
              },
              legend: {
                  enabled: !this.isViewportMobile, // Disable legend on mobile
                  align: 'center',
                  verticalAlign: 'top',
                  itemStyle: {
                    color: 'var(--color-text-primary, #0F0F1A)',
                    fontWeight: '500',
                    fontSize: '13px'
                  },
                  itemHoverStyle: {
                    color: 'var(--primary, #00aedd)'
                  }
              },
              responsive: {
                  rules: [{
                    condition: { maxWidth: 500 },
                    chartOptions: {
                      legend: { 
                        enabled: false // Disable legend on small screens for space
                      },
                      chart: {
                        spacingTop: 15,
                        spacingBottom: 10,
                        spacingLeft: 10,
                        spacingRight: 10
                      }
                    }
                  }]
              },
              // Boost module for better performance with large datasets
              boost: {
                enabled: true,
                useGPUTranslations: true,
                seriesThreshold: this.isViewportMobile ? 1 : 3 // Lower threshold on mobile
              }
            };
            
            self.$_chart = Highcharts.chart('highchart-content', chartOptions);
        } catch(e) {
            console.error("Error creating Highcharts chart:", e);
            self.$_chart = null;
            self.isChartLoading = false;
            self.chartUpdateInProgress = false;
        }
      });
    },
    //call to api to load the indexes created by the user
    _loadAllIndexCreator: function(){
        let success = response => {
          // Ensure response.data is an array
          this.user_indices_creator = Array.isArray(response.data) ? response.data : [];
        };
        let failure = (error) => { // Ensure correct arrow function syntax
             console.error("Error loading indices:", error);
             this.$vueOnToast.pop('error', "Error", "No se pudieron cargar los índices guardados.");
             this.user_indices_creator = []; // Reset on failure
        };
        let url = '/api/v1/stock-finder/list-index-creator';
        const successHandler = new APICaller.SuccessHandler(success);
        // Pass the failure callback to FailureHandler
        const failureHandler = new APICaller.FailureHandler('077', 'Error cargando índices.', failure);

        this._getAPICall(url, successHandler, failureHandler);
    },

    //method to add the selected ticker to the index that is being created
    _addSelectedTicker:function(ticker){
      // Ensure ticker is valid before adding
      if (!ticker) {
          this.$vueOnToast.pop('warning', "Ticker inválido", "No se ha seleccionado ningún ticker.");
          return;
      }
      if(this.user_tickers.indexOf(ticker) == -1){
        // Use spread syntax for cleaner array update (optional, push is fine)
        this.user_tickers = [...this.user_tickers, ticker]; 
        // Alternatively: this.user_tickers.push(ticker);

        this._getData();
      } else {
        this.$vueOnToast.pop('error', "Error", "No pueden haber elementos repetidos. Este ticker ya se encuentra entre los seleccionados.");
      }
    },
    // This method seems related to a specific input component not present, might be removable
    // _getValueFromComponentIndexName(value) {
    //   this.input_IndexName_value = value[0];
    //   this.index_creator_name = this.input_IndexName_value;
    // },
    //call to api to collect all the data about the user's tickers and indexes
    _getData: function(){
      // Prevent simultaneous updates
      if (this.chartUpdateInProgress) {
        console.log("Chart update already in progress, skipping request");
        return;
      }
      
      // Cancel any pending debounced calls
      if (this.chartDebounceTimer) {
        clearTimeout(this.chartDebounceTimer);
      }
      
      if (!this.user_tickers || this.user_tickers.length === 0) {
        // Reset chart with default empty data and redraw
        this.series = [{
          name: 'Índice de cartera',
          data: [0, 0] 
        }];
        this.categories = [];
        this.max_drawdown = 0;
        this.profit = 0;
        this._addDataToHighchart();
        return;
      }

      // Set loading state
      this.isChartLoading = true;
      
      // Debounce API calls to prevent too many requests
      this.chartDebounceTimer = setTimeout(() => {
        // Ensure tickers are joined correctly as a comma-separated string
        let query = "?tickers=" + this.user_tickers.join(','); 
  
        let success = response => {
          // Add more robust checks for response structure
          if (response && response.data && Array.isArray(response.data.series)) {
            // Store the series data
            this.series = response.data.series;
            // Ensure categories data exists and is structured correctly
            this.categories = response.data.categories && Array.isArray(response.data.categories) && response.data.categories[0] && Array.isArray(response.data.categories[0].data)
              ? response.data.categories[0].data 
              : [];
            // Use || 0 to default to zero if values are null/undefined
            this.max_drawdown = response.data.max_drawdown || 0; 
            this.profit = response.data.profit || 0;
  
            // Redraw the chart with new data
            this._addDataToHighchart();
          } else {
            // Handle potentially malformed success response
            console.error("Invalid data structure received from API:", response);
            this.$vueOnToast.pop('error', "Error de Datos", "Respuesta inesperada del servidor al calcular el índice.");
            // Reset chart to empty state on error
            this.series = [{ name: 'Índice de cartera', data: [0, 0] }];
            this.categories = [];
            this.max_drawdown = 0;
            this.profit = 0;
            this._addDataToHighchart();
          }
        };
        
        let failure = (error) => {
             console.error("Error fetching portfolio data:", error);
             this.$vueOnToast.pop('error', "Error", "No se pudieron cargar los datos del índice.");
             // Reset chart to empty state on failure
             this.series = [{ name: 'Índice de cartera', data: [0, 0] }];
             this.categories = [];
             this.max_drawdown = 0;
             this.profit = 0;
             this._addDataToHighchart();
        };
  
        let url = "/api/v1/portfolio/calculate-portfolio-index-from-tickers" + query;
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('078', 'Error cargando valores.', failure);
  
        this._getAPICall(url, successHandler, failureHandler);
      }, this.chartDebounceDelay);
    },
    //method to remove one of the selected tickers
    _deleteTicker : function(index){
       // Check index validity
       if (index >= 0 && index < this.user_tickers.length) {
            this.user_tickers.splice(index, 1);
            this._getData(); // Refresh data after deletion
       } else {
           console.warn("Attempted to delete ticker with invalid index:", index);
       }
    },
    //method to load the tickers of the selected index (called internally, not directly by user usually)
    _loadIndexData: function(index){
       // This seems to be the core logic for loading, maybe rename _loadIndex if it's not user-facing action
      this.selected_index = index.icid;
      this.selectedIndexFromModal = index; // Keep track of the full index object
      
      if(index && typeof index.tickers === 'string'){
        // Ensure tickers are split correctly, handle potential empty strings
        this.user_tickers = index.tickers.split(',').filter(t => t.trim() !== ''); // Filter empty tickers
        this.original_tickers = [...this.user_tickers]; // Store a copy as original state
        this._getData(); // Fetch data for the loaded tickers
      } else {
           console.warn("Attempted to load index with invalid data:", index);
           // Reset state if load fails
           this._deselectIndex(); 
      }
    },
    //method to trigger showing the delete confirmation modal
    _removeIndex: function(index){
      this.indexToDelete = index;
      this.showDeleteConfirmModal = true;
      
      // Hide the load index modal if open and set flag to reopen it later
      if (this.showLoadIndexModal) {
        this.showLoadIndexModal = false;
        this._shouldReopenLoadModal = true; // Flag to reopen load modal after delete confirmation
      }
    },
    //method to confirm deletion after modal confirmation
    _confirmDeleteIndex: function() {
      if (!this.indexToDelete || !this.indexToDelete.icid) {
           console.error("Cannot delete index: indexToDelete is invalid.");
           return;
      }
      
      let params = new URLSearchParams();
      params.append('icid', this.indexToDelete.icid);

      let success = response => {
        // Check response status explicitly
        if (response && response.data && response.data.status === 'success') {
            // Standardize quotes and use template literal
            this.$vueOnToast.pop('success', 'Éxito', `Índice "${this.indexToDelete.name}" eliminado correctamente.`);
            this._loadAllIndexCreator(); // Refresh the list of indices
            // If the deleted index was the currently selected one, deselect it
            if (this.selected_index === this.indexToDelete.icid) {
                this._deselectIndex();
            }
        } else {
            // Provide more specific error message from backend if available
            const errorMsg = response && response.data && response.data.message ? response.data.message : 'No se pudo eliminar el índice.';
            this.$vueOnToast.pop('error', "Error", errorMsg);
        }
        
        // Reset delete modal state regardless of success/failure
        this.showDeleteConfirmModal = false;
        this.indexToDelete = null;
        
        // Reopen the load index modal if it was previously open
        if (this._shouldReopenLoadModal) {
          this._shouldReopenLoadModal = false;
          // Use nextTick to ensure DOM updates before showing modal
          this.$nextTick(() => {
              this.showLoadIndexModal = true;
          });
        }
      };

      let failure = (error) => { // Ensure correct arrow function syntax
         console.error("Error deleting index:", error);
         this.$vueOnToast.pop('error', "Error", "Ocurrió un error al intentar eliminar el índice.");
        // Reset and close modal even on failure
        this.showDeleteConfirmModal = false;
        this.indexToDelete = null;
        
        // Reopen the load index modal if it was previously open
        if (this._shouldReopenLoadModal) {
          this._shouldReopenLoadModal = false;
           this.$nextTick(() => {
              this.showLoadIndexModal = true;
           });
        }
      };

      let url = '/api/v1/stock-finder/delete-index-creator';

      const successHandler = new APICaller.SuccessHandler(success);
      // Pass failure callback
      const failureHandler = new APICaller.FailureHandler('276', 'Error borrando índice.', failure);

      this._postAPICall(url, params, successHandler, failureHandler);
    },
    //method to save all selected tickers in a new index - updated
    _saveIndex: function(){
      // Trim the name and check length
      const trimmedName = this.index_creator_name.trim();
      if (!trimmedName || trimmedName.length > 30) {
          this.$vueOnToast.pop('warning', "Nombre inválido", "El nombre del índice no puede estar vacío y debe tener 30 caracteres o menos.");
          return; 
      }
       if (this.user_tickers.length === 0) {
           this.$vueOnToast.pop('warning', "Tickers vacíos", "Debe seleccionar al menos un ticker para guardar el índice.");
           return;
       }
      
      let params = new URLSearchParams();
      params.append('name', trimmedName);
      // Ensure tickers are joined correctly
      params.append('tickers', this.user_tickers.join(',')); 

      let success= response => {
        // Check response status explicitly
        if (response && response.data && response.data.status === 'success') {
          // Standardize quotes and use template literal
          this.$vueOnToast.pop('success', 'Éxito', `Índice "${trimmedName}" guardado correctamente.`);
          this.index_creator_name = ''; // Clear input field
          this._loadAllIndexCreator(); // Refresh index list
          this._closeSaveIndexModal(); // Close modal after successful save
           // Optionally load the newly saved index?
           // Find the new index ID from response if available and call _loadIndexData
           if(response.data.new_index_id) {
               // Assuming the response includes the new ID and the full index data
               const newIndex = this.user_indices_creator.find(idx => idx.icid === response.data.new_index_id);
               if(newIndex) {
                  this._loadIndexData(newIndex); // Load the newly created index
               }
           }
        } else {
           // Provide specific error message from backend if available
            const errorMsg = response && response.data && response.data.message ? response.data.message : 'No se pudo guardar el índice.';
            this.$vueOnToast.pop('error', "Error", errorMsg);
        }
      };
      
       let failure = (error) => { // Ensure correct arrow function syntax
           console.error("Error saving index:", error);
           this.$vueOnToast.pop('error', "Error", "Ocurrió un error al intentar guardar el índice.");
           // Keep modal open on failure? Or close? User preference.
           // this._closeSaveIndexModal(); 
       };

      let url = '/api/v1/stock-finder/save-index-creator';

      const successHandler = new APICaller.SuccessHandler(success);
      // Pass failure callback
      const failureHandler = new APICaller.FailureHandler('277', 'Error guardando índice.', failure);

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    _autocompleteSuggestions: function(option) {
      // Trim and validate input before sending API call
       const searchTerm = option ? option.trim() : '';
       if (!searchTerm) {
           this.options = []; // Clear options if search term is empty
           return;
       }
      
      // Assuming ticker, currency, entries_analysis are component data properties if used elsewhere
      // If not, they might be leftovers and could be removed unless needed for the API call.
      // this.ticker = searchTerm; // Update ticker if needed for the call
      // this.currency = 'EUR'; 
      // this.entries_analysis = [];

      let success = response => {
        // Ensure response structure is valid
        if (response && typeof response.data === 'object' && response.data !== null) {
            this.options = Object.entries(response.data).map(([key, value]) => ({ 
                title: key, 
                description: value 
            }));
        } else {
             console.warn("Received invalid data for autocomplete:", response);
             this.options = []; // Clear options on invalid data
        }
      };
      
      let failure = (error) => { // Ensure correct arrow function syntax
          console.error("Error loading autocomplete suggestions:", error);
          this.$vueOnToast.pop('error', "Error", "No se pudieron cargar las sugerencias.");
          this.options = []; // Clear options on failure
      };


      let url = '/api/v1/portfolio/waitings/autocomplete/' + encodeURIComponent(searchTerm); // Encode search term

      const successHandler = new APICaller.SuccessHandler(success);
       // Pass failure callback
      const failureHandler = new APICaller.FailureHandler('079', 'Error cargando autocompletado.', failure);

      this._getAPICall(url, successHandler, failureHandler);
    },

    // This method seems unused or related to removed component, consider removing
    // _getValueFromSimulatorIndex(value) {
    //   this.input_simulator_value = value[0];
    // },

    ...mapMutations(['setSelectedTicker']), // Fix: Add trailing comma if needed by linter, but usually not required here

    // New methods for modal management
    _openLoadIndexModal: function() {
      // Make sure other modals are closed first
      this.showDeleteConfirmModal = false;
      this.indexToDelete = null;
      this.showSaveIndexModal = false; 
      this.showTickerSearchModal = false; 
      
      // Open the load modal
      this.showLoadIndexModal = true;
      this._loadAllIndexCreator(); // Refresh the index list when opening modal
    },
    
    _closeLoadIndexModal: function() {
      this.showLoadIndexModal = false;
      // If user closed modal without confirming, clear the temporary selection
      if (!this.original_tickers || this.original_tickers.length === 0) {
        // Only reset if not already loaded (indicated by original_tickers)
        this.selected_index = "";
        this.selectedIndexFromModal = null;
      }
    },
    
    _selectIndexFromModal: function(index) {
      // Temporarily selects index in the modal list, doesn't load it yet
      this.selectedIndexFromModal = index; // Store the full index object temporarily
      this.selected_index = index.icid; // Store just the ID for comparison/key
    },
    
    _confirmLoadIndex: function() {
      // Only load the index when user confirms by clicking "Cargar"
      if (this.selected_index && this.selectedIndexFromModal) {
        this._loadIndexData(this.selectedIndexFromModal); // This actually loads the index data
        this._closeLoadIndexModal();
        this.$vueOnToast.pop('info', "Índice cargado", `Viendo "${this.selectedIndexFromModal.name}"`);
      } else {
        this.$vueOnToast.pop('warning', "Selección requerida", "Por favor, seleccione un índice para cargar.");
      }
    },

    _editIndex: function(index){
      // Loads the index for editing directly from the list (without confirmation step)
       this._loadIndexData(index); // Load data and set original tickers
       this._closeLoadIndexModal(); // Close modal after selection
       this.$vueOnToast.pop('info', "Viendo índice", `Editando "${index.name}"`);
    },

    // Method to close delete confirmation modal
    _closeDeleteConfirmModal: function() {
      this.showDeleteConfirmModal = false;
      this.indexToDelete = null;
      
      // Reopen the load index modal if it was previously open
      if (this._shouldReopenLoadModal) {
        this._shouldReopenLoadModal = false;
        // Use nextTick to ensure DOM updates before showing modal
        this.$nextTick(() => {
            this.showLoadIndexModal = true;
        });
      }
    },

    // Make sure other modals are closed when opening save modal
    _openSaveIndexModal: function() {
      // Close other modals
      this.showLoadIndexModal = false;
      this.showDeleteConfirmModal = false;
      this.indexToDelete = null;
      this.showTickerSearchModal = false;
      
      // Clear name input when opening for a *new* index
      this.index_creator_name = ''; 
      
      // Show save modal
      this.showSaveIndexModal = true;
    },

    _closeSaveIndexModal: function() {
      this.showSaveIndexModal = false;
      this.index_creator_name = ''; // Clear name input on close
    },

    // New methods for handling changes to a selected/loaded index
    _saveIndexChanges: function() {
      // Validate state before proceeding
      if (!this.selectedIndexFromModal || !this.selectedIndexFromModal.icid) {
          console.error("Cannot save changes: No index selected or invalid index data.");
          this.$vueOnToast.pop('error', "Error", "No hay un índice seleccionado para guardar cambios.");
          return;
      }
       if (!this.hasIndexChanges) {
           this.$vueOnToast.pop('info', "Sin cambios", "No hay cambios para guardar en el índice seleccionado.");
           return;
       }
        if (this.user_tickers.length === 0) {
           this.$vueOnToast.pop('warning', "Tickers vacíos", "Un índice no puede guardarse sin tickers.");
           return;
       }
      
      let params = new URLSearchParams();
      // Use the existing index name (editing doesn't change name here, only tickers)
      params.append('name', this.selectedIndexFromModal.name); 
      params.append('tickers', this.user_tickers.join(','));
      params.append('icid', this.selectedIndexFromModal.icid);

      let success = response => {
         // Check response status explicitly
        if (response && response.data && response.data.status === 'success') {
          this.$vueOnToast.pop('success', "Éxito", `Cambios guardados para el índice "${this.selectedIndexFromModal.name}".`);
          this._loadAllIndexCreator(); // Refresh index list (in case data like ticker string changes)
          // Update original_tickers to reflect the saved state
          this.original_tickers = [...this.user_tickers]; 
          // Update the selectedIndexFromModal object in case the ticker string representation changed
          this.selectedIndexFromModal.tickers = this.user_tickers.join(',');
        } else {
            // Provide specific error message from backend if available
            const errorMsg = response && response.data && response.data.message ? response.data.message : 'No se pudieron guardar los cambios.';
            this.$vueOnToast.pop('error', "Error", errorMsg);
        }
      };
      
      let failure = (error) => { // Ensure correct arrow function syntax
          console.error("Error saving index changes:", error);
          this.$vueOnToast.pop('error', "Error", "Ocurrió un error al intentar guardar los cambios del índice.");
      };

      // Assuming endpoint for editing exists
      let url = '/api/v1/stock-finder/edit-index-creator'; 
      
      const successHandler = new APICaller.SuccessHandler(success);
      // Pass failure callback
      const failureHandler = new APICaller.FailureHandler('582', 'Error guardando cambios del índice.', failure);

      this._postAPICall(url, params, successHandler, failureHandler);
    },
    
    _discardIndexChanges: function() {
      // Reload the original index data only if an index is selected and changes exist
      if (this.selectedIndexFromModal && this.hasIndexChanges) {
        // Use the stored original tickers
        this.user_tickers = [...this.original_tickers]; 
        this._getData(); // Refresh the chart/data display
        this.$vueOnToast.pop('info', "Cambios descartados", `Restaurado el índice "${this.selectedIndexFromModal.name}".`);
      } else if (this.selectedIndexFromModal) {
           this.$vueOnToast.pop('info', "Sin cambios", "No había cambios para descartar.");
      }
      // Do nothing if no index is selected
    },
    
    _deselectIndex: function() {
       const deselectedName = this.selectedIndexFromModal ? this.selectedIndexFromModal.name : null;
      // Clear selection, data and tickers
      this.selected_index = "";
      this.selectedIndexFromModal = null;
      this.original_tickers = []; // Clear original tickers
      this.user_tickers = []; // Clear all selected tickers
      this._getData(); // Update chart with empty tickers
       if (deselectedName) {
           this.$vueOnToast.pop('info', "Índice deseleccionado", `Ya no está viendo "${deselectedName}".`);
       } else {
            this.$vueOnToast.pop('info', "Índice deseleccionado");
       }
    },

    // New methods for ticker search modal
    _openTickerSearchModal: function() {
      // Close other modals first
       this.showLoadIndexModal = false;
       this.showSaveIndexModal = false;
       this.showDeleteConfirmModal = false;

      this.showTickerSearchModal = true;
      this.options = []; // Clear previous options
       // Maybe focus the input field in the modal? Requires ref on AutocompleteInput
       // this.$nextTick(() => this.$refs.autocompleteInputRef?.focus());
    },
    
    _closeTickerSearchModal: function() {
      this.showTickerSearchModal = false;
      this.options = []; // Clear options on close
    },
    
    _selectSuggestions: function(option) {
      // Add the selected ticker to the list if it's valid and not already present
       if (option && option.title) {
           const tickerToAdd = option.title;
            if (this.user_tickers.indexOf(tickerToAdd) === -1) {
                this.user_tickers = [...this.user_tickers, tickerToAdd];
                this._getData(); // Refresh data
                this._closeTickerSearchModal(); // Close the modal after selection
                this.$vueOnToast.pop('success', "Ticker añadido", tickerToAdd);
            } else {
                this.$vueOnToast.pop('error', "Duplicado", `El ticker "${tickerToAdd}" ya está en la lista.`);
                // Keep the modal open if there's an error
            }
       } else {
           console.warn("Invalid suggestion selected:", option);
            this.$vueOnToast.pop('warning', "Selección inválida", "La sugerencia seleccionada no es válida.");
       }
    },
  },
}
</script>


<style lang="scss" scoped>
  /* Modern full-page layout with dark theme */
  #portfolio-creator-calculator {
    width: 100%;
    height: 100vh;
    max-width: 100%;
    overflow: hidden;
    background-color: var(--color-background, #0F0F1A);
    box-sizing: border-box;
    position: relative;
  }

  .portfolio-creator-view {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  /* Main grid layout */
  .portfolio-content-grid {
    display: grid;
    grid-template-columns: 1fr 350px;
    gap: 20px;
    max-width: 1800px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  /* Left column - chart and metrics */
  .chart-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    max-height: 100%;
    overflow: hidden;
  }

  /* Metrics grid */
  .metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    flex-shrink: 0;
  }

  .metric-card {
    background-color: var(--color-surface, #20202B);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }

  .metric-title {
    margin: 0 0 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-secondary, #BFC2CD);
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .metric-value {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-text-primary, #FFFFFF);
  }

  .positive-value {
    color: var(--color-success, #57a869);
  }
  
  .negative-value {
    color: var(--color-error, #B00020);
  }

  /* Chart container */
  .chart-container {
    background-color: var(--color-surface, #20202B);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    flex-grow: 1;
    min-height: 300px;
    height: 60vh; /* Default height value - will be dynamically updated */
    position: relative;
    will-change: transform; /* Performance hint for browsers */
    contain: layout style size; /* More specific containment for better performance */
    -webkit-transform: translate3d(0, 0, 0); /* Force GPU acceleration */
    transform: translate3d(0, 0, 0);
    display: flex; /* Added to ensure child fills height */
    flex-direction: column; /* Stack children vertically */
  }

  /* Add loading overlay for chart */
  .chart-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(32, 32, 43, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px); /* Safari support */
  }

  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 174, 221, 0.3);
    border-radius: 50%;
    border-top-color: var(--color-highlight, #00aedd);
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
  }

  @keyframes spin {
    to { transform: rotate(360deg); }
  }

  #highchart-content {
    width: 100%;
    height: 100%; /* Fill container height */
    flex-grow: 1; /* Take all available space */
    contain: layout style; /* Performance optimization */
    -webkit-transform: translate3d(0, 0, 0); /* Force GPU acceleration */
    transform: translate3d(0, 0, 0);
  }

  /* Info panel */
  .info-panel {
    background-color: var(--color-surface, #20202B);
    border-radius: 10px;
    padding: 15px 20px;
    border-left: 4px solid var(--color-highlight, #00aedd);
    flex-shrink: 0; /* Prevent compression */
  }
  
  .info-text-style {
    margin: 0;
    font-size: 14px;
    line-height: 1.6;
    color: var(--color-text-secondary, #BFC2CD);
  }

  /* Right column - tickers and actions */
  .tickers-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 100%;
    overflow-y: auto; /* Allow scrolling in the tickers column if needed */
    box-sizing: border-box;
    padding-right: 2px; /* Slight padding for scrollbar */
  }

  /* Selected index container */
  .selected-index-container {
    background-color: var(--color-active-surface, #193141);
    border: 1px solid rgba(0, 174, 221, 0.3);
    border-left: 4px solid var(--color-highlight, #00aedd);
    border-radius: 10px;
    padding: 15px;
  }
  
  .selected-index-header {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .selected-index-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text-primary, #FFFFFF);
    margin: 0;
  }
  
  .selected-index-title span {
    color: var(--color-highlight, #00aedd);
  }
  
  .changes-status {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    margin: 10px 0 0;
    font-size: 12px;
    color: var(--color-highlight, #00aedd);
    background-color: rgba(0, 174, 221, 0.15);
    padding: 4px 10px;
    border-radius: 12px;
    font-weight: 500;
  }
  
  .selected-index-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  /* Tickers section */
  .tickers-section {
    background-color: var(--color-surface, #20202B);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  }

  .section-title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text-primary, #FFFFFF);
  }

  /* Empty state */
  .empty-tickers-state {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    color: var(--color-text-secondary, #BFC2CD);
    text-align: center;
  }

  .empty-icon {
    font-size: 48px;
    margin-bottom: 10px;
    color: var(--color-text-inactive, rgba(255, 255, 255, 0.6));
  }

  .empty-tickers-state p {
    margin: 5px 0;
  }

  /* Tickers list */
  .tickers-list {
    flex-grow: 1;
    padding: 15px;
    overflow-y: auto;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .ticker-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    min-width: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .ticker-info {
    flex: 1;
    min-width: 0;
    overflow: hidden;
    margin-right: 8px;
  }

  .ticker-label {
    font-weight: 600;
    font-size: 15px;
    color: var(--color-text-primary, #FFFFFF);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .delete-button {
    color: var(--color-text-secondary, #BFC2CD);
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    flex-shrink: 0;
    min-width: 30px;
    height: 30px;
  }

  .delete-button:hover {
    background-color: rgba(176, 0, 32, 0.2);
    color: var(--color-error, #B00020);
  }

  .delete-button i {
    font-size: 20px;
  }

  /* Portfolio actions */
  .portfolio-actions {
    display: flex;
    gap: 10px;
  }

  .action-btn-lg {
    flex: 1;
    height: 46px;
    font-size: 15px;
  }

  /* Button styles */
  .action-btn {
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 38px;
    transition: all 0.2s ease;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    white-space: nowrap;
  }

  .action-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: #292938;
    color: var(--color-text-inactive, rgba(255, 255, 255, 0.6));
    border-color: transparent;
    box-shadow: none;
  }
  
  .primary-btn {
    background-color: var(--color-highlight, #00aedd);
    color: var(--color-text-on-highlight, #FFFFFF);
  }
  .primary-btn:not(:disabled):hover {
    background-color: #0095c0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }

  .secondary-btn {
    background-color: transparent;
    color: var(--color-text-primary, #FFFFFF);
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  }
  .secondary-btn:not(:disabled):hover {
    background-color: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.5);
  }
  
  .tertiary-btn {
    background-color: var(--color-muted, #343948);
    color: var(--color-text-primary, #FFFFFF);
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  }
  .tertiary-btn:not(:disabled):hover {
    background-color: #3d4255;
  }
  
  .success-btn {
    background-color: var(--color-success, #57a869);
    color: var(--color-primary, #FFFFFF);
  }
  .success-btn:not(:disabled):hover {
    background-color: #4c9a5d;
  }
  
  .warning-btn {
    background-color: var(--color-warning, #FF9B00);
    color: var(--color-primary, #FFFFFF);
  }
  .warning-btn:not(:disabled):hover {
    background-color: #e08c00;
  }

  .delete-btn {
    background-color: var(--color-error, #B00020);
    color: var(--color-primary, #FFFFFF);
  }
  .delete-btn:not(:disabled):hover {
    background-color: #9a001b;
  }

  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  
  .modal-container {
    background-color: var(--color-background, #0F0F1A);
    border-radius: 12px;
    width: 100%;
    max-width: 550px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4);
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  }
  
  .modal-header {
    padding: 16px 24px;
    border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text-primary, #FFFFFF);
  }
  
  .modal-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-text-secondary, #BFC2CD);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-close-btn:hover {
    color: var(--color-text-primary, #FFFFFF);
  }
  
  .modal-close-btn i {
    font-size: 22px;
  }
  
  .modal-body {
    padding: 24px;
    overflow-y: auto;
    flex-grow: 1;
  }
  
  .modal-footer {
    padding: 16px 24px;
    border-top: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    background-color: var(--color-surface, #20202B);
  }
  
  /* Load Index Modal Specifics */
  .modal-indices-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 400px;
    overflow-y: auto;
    padding-right: 5px;
  }
  
  .modal-index-item {
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: var(--color-surface, #20202B);
  }
  
  .modal-index-item:hover {
    background-color: var(--color-muted, #343948);
    border-color: var(--color-outline, rgba(255, 255, 255, 0.4));
  }
  
  .modal-index-item.selected-index {
    background-color: var(--color-active-surface, #193141);
    border-color: var(--color-highlight, #00aedd);
    box-shadow: 0 0 0 1px var(--color-highlight, #00aedd) inset;
  }
  
  .modal-index-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
    margin-right: 10px;
  }
  
  .modal-index-name {
    font-weight: 600;
    font-size: 16px;
    color: var(--color-text-primary, #FFFFFF);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .modal-index-tickers {
    font-size: 13px;
    color: var(--color-text-secondary, #BFC2CD);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .modal-index-actions {
    display: flex;
    gap: 8px;
  }
  
  .icon-button {
    color: var(--color-text-secondary, #BFC2CD);
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
  }
  
  .edit-index-btn:hover {
    background-color: rgba(0, 174, 221, 0.15);
    color: var(--color-highlight, #00aedd);
  }
  
  .delete-index-btn:hover {
    background-color: rgba(176, 0, 32, 0.15);
    color: var(--color-error, #B00020);
  }
  
  .no-indices-message {
    text-align: center;
    padding: 40px 20px;
    color: var(--color-text-secondary, #BFC2CD);
    font-style: italic;
    background-color: var(--color-muted, #343948);
    border-radius: 6px;
  }

  /* Delete confirmation styles */
  .delete-confirm-modal {
    max-width: 450px;
  }
  
  .delete-confirm-message {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 16px;
    color: var(--color-text-primary, #FFFFFF);
    text-align: center;
  }
  
  .delete-confirm-message strong {
    color: var(--color-error, #B00020);
  }
  
  .delete-warning {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-warning, #FF9B00);
    font-size: 14px;
    background-color: rgba(255, 155, 0, 0.15);
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid var(--color-warning, #FF9B00);
  }
  
  .warning-icon {
    font-size: 20px;
  }

  /* Save Index Modal Styles */
  .save-index-modal {
    max-width: 480px;
  }
  
  .save-index-form {
    width: 100%;
  }

  .form-group {
    margin-bottom: 20px;
    width: 100%;
  }
  
  .input-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-primary, #FFFFFF);
  }
  
  .vanilla-input {
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    font-size: 15px;
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    border-radius: 6px;
    background-color: var(--color-muted, #343948);
    color: var(--color-text-primary, #FFFFFF);
    transition: all 0.2s ease;
    box-sizing: border-box;
  }
  
  .vanilla-input:focus {
    outline: none;
    border-color: var(--color-highlight, #00aedd);
    box-shadow: 0 0 0 3px rgba(0, 174, 221, 0.2);
  }
  
  .vanilla-input::placeholder {
    color: var(--color-text-inactive, rgba(255, 255, 255, 0.6));
  }
  
  .input-helper {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: var(--color-text-secondary, #BFC2CD);
    margin-top: 6px;
  }
  
  .index-tickers-summary {
    background-color: var(--color-surface, #20202B);
    border-radius: 6px;
    padding: 12px 16px;
    margin-top: 10px;
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
  }
  
  .tickers-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    color: var(--color-text-primary, #FFFFFF);
  }
  
  .tickers-list {
    font-size: 13px;
    line-height: 1.5;
    color: var(--color-text-secondary, #BFC2CD);
    word-break: break-word;
    overflow-y: auto;
  }
  
  .no-tickers-message {
    background-color: var(--color-surface, #20202B);
    border-radius: 6px;
    padding: 20px;
    text-align: center;
    color: var(--color-text-secondary, #BFC2CD);
    margin-top: 10px;
    border: 1px dashed var(--color-outline, rgba(255, 255, 255, 0.3));
  }
  
  .tickers-helper {
    font-size: 13px;
    line-height: 1.4;
    margin-top: 8px;
    font-style: italic;
    color: var(--color-text-inactive, rgba(255, 255, 255, 0.6));
  }

  /* Ticker Search Modal */
  .ticker-search-modal {
    max-width: 500px;
  }
  
  .ticker-search-form {
    width: 100%;
  }
  
  .autocomplete-wrapper {
    position: relative;
    width: 100%;
  }
  
  .search-helper-text {
    font-size: 12px;
    color: var(--color-text-secondary, #BFC2CD);
    margin-top: 6px;
    text-align: center;
    font-style: italic;
  }
  
  :deep(.autocomplete-input ul) {
    max-height: 250px;
    overflow-y: auto;
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    border-top: none;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    background-color: var(--color-background, #0F0F1A);
    z-index: 10;
  }

  :deep(.autocomplete-input ul li) {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.15s ease;
  }

  :deep(.autocomplete-input ul li:hover),
  :deep(.autocomplete-input ul li.active) {
    background-color: var(--color-muted, #343948);
  }

  .autocomplete-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .autocomplete-item-content {
    flex: 1;
    min-width: 0;
    overflow: hidden;
  }
  
  .autocomplete-item-title {
    font-weight: 500;
    font-size: 15px;
    color: var(--color-text-primary, #FFFFFF);
    margin: 0 0 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .autocomplete-item-description {
    font-size: 13px;
    color: var(--color-text-secondary, #BFC2CD);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Not available view */
  .not-available-data-container {
    text-align: center;
    max-width: 700px;
    margin: 40px auto;
    padding: 30px;
    border-radius: 8px;
    background-color: var(--color-surface, #20202B);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }

  .info-ticker-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .text-info-ticker {
    text-align: center;
    background-color: var(--color-muted, #343948);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 10px 25px;
    border-radius: 4px;
    border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.3));
    color: var(--color-text-primary, #FFFFFF);
  }

  .text-bold {
    font-weight: 600;
    color: var(--color-highlight, #00aedd);
  }

  .not-available-data {
    color: var(--color-warning, #FF9B00);
    margin: 20px 0;
    font-size: 15px;
  }

  .link-to-store {
    color: var(--color-highlight, #00aedd);
    text-decoration: none;
    font-weight: 500;
    font-size: 15px;
    display: inline-block;
    margin-top: 10px;
  }
  
  .link-to-store:hover {
    text-decoration: underline;
    opacity: 0.9;
  }

  /* Responsive design */
  @media (max-width: 1200px) {
    .portfolio-content-grid {
      grid-template-columns: 1fr 300px;
    }
    
    /* Let dynamic height calculation handle this instead of fixed height */
    #highchart-content {
      height: 100%;
    }
  }

  @media (max-width: 992px) {
    .portfolio-content-grid {
      grid-template-columns: 1fr;
      max-height: none; /* Remove max-height constraint */
      overflow: visible; /* Allow content to flow */
      height: auto;
    }
    
    .tickers-column {
      order: -1;
      max-height: none;
      overflow-y: visible;
    }
    
    .selected-index-container {
      order: -1;
    }
    
    /* Chart column should have proper sizing on mobile */
    .chart-column {
      max-height: none;
      overflow: visible;
    }
    
    #portfolio-creator-calculator,
    .portfolio-creator-view {
      height: auto;
      min-height: 100vh;
      overflow: visible;
    }
    
    /* Ensure metrics are visible on mobile */
    .metrics-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
  }

  @media (max-width: 768px) {
    .portfolio-creator-view {
      padding: 10px;
    }
    
    /* Let dynamic height calculation handle this */
    .chart-container, #highchart-content {
      min-height: 250px;
    }
    
    .metrics-grid {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    
    /* Restored and improved portfolio actions for mobile */
    .portfolio-actions {
      flex-direction: column;
      gap: 8px;
    }
    
    .modal-footer {
      flex-direction: column-reverse;
      gap: 8px;
    }
    
    .modal-footer .action-btn {
      width: 100%;
    }
    
    .selected-index-actions {
      flex-direction: column;
      gap: 8px;
    }
    
    .selected-index-actions .action-btn {
      width: 100%;
    }
    
    /* Improved mobile metrics */
    .metric-card {
      padding: 12px;
    }
    
    .metric-title {
      font-size: 12px;
      margin-bottom: 8px;
    }
    
    .metric-value {
      font-size: 18px;
    }
    
    /* Adjust modal size for mobile */
    .modal-container {
      max-width: 92vw;
      margin: 0 4vw;
    }
    
    .modal-header {
      padding: 12px 16px;
    }
    
    .modal-body {
      padding: 16px;
    }
    
    .modal-footer {
      padding: 12px 16px;
    }
  }

  /* Small phones */
  @media (max-width: 480px) {
    /* Let dynamic height calculation handle this */
    .chart-container, #highchart-content {
      min-height: 200px;
    }
    
    .loading-spinner {
      width: 30px;
      height: 30px;
      border-width: 2px;
    }
  }

  /* Hardware acceleration for smoother animations */
  .chart-container, #highchart-content, .modal-container {
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
    -webkit-font-smoothing: antialiased;
  }
  
  /* Apply box-sizing to all elements for consistent layout */
  * {
    box-sizing: border-box;
  }
</style>

